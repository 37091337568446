import React from 'react'
import { Helmet } from "react-helmet"
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import BackgroundImage from 'gatsby-background-image'
import GoogleReviews from '../components/google_reviews_slider'
import Copyright from '../components/copyright'
import FormContact from '../components/form_contact'
import Img from "gatsby-image"

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Helmet>
                <link href="https://fonts.googleapis.com/css2?family=Oswald&display=swap" rel="stylesheet" />
            </Helmet>
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
            <div className="layout">
                <BackgroundImage fluid={entry.frontmatter.image.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                    <div className="title__gradient">
                        <div className="layout__container">
                            <div className="layout__wide title center">
                                <h1>{entry.frontmatter.heading}</h1>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            </div>
            <div className="layout grey">
                <div className="layout__container">
                    <div className="layout__wide">
                        <nav className="breadcrumb">
                            <p><Link to='/'>Home</Link> &nbsp;&rsaquo;&nbsp; <strong>{entry.frontmatter.title}</strong></p>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="layout body">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div className="grid grid__2-1 grid__gap--48 text">
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                                <FormContact />
                            </div>
                            <div className="aside">
                                <iframe title="Google Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1637061.385818101!2d173.73517315653905!3d-36.73731642131609!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d3925f94fcda3%3A0xc2edc47876b3314c!2sEden%20Garage%20Doors!5e0!3m2!1sen!2snz!4v1605235333058!5m2!1sen!2snz" width="100%" height="480" frameborder="0" allowfullscreen="" aria-hidden="false"></iframe>
                                <figure className="partners">
                                    <a href="https://www.topreviews.co.nz/best-garage-door-repair-auckland/#7_Eden_Garage_Doors" title="Top Reviews" rel="nofollow noopener"><Img fixed={data.top_reviews.childImageSharp.fixed} alt='Top Reviews' /></a>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GoogleReviews />
            <Copyright />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
        top_reviews: file(relativePath: { eq: "top_reviews.jpg" }) {
            childImageSharp {
                fixed(width: 150) {
                    ...GatsbyImageSharpFixed
                  }
            }
        }
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
                title
				page_title
                meta_description
                heading
                image {
                    childImageSharp {
                        fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
			}
			fields {
				slug
			}
			html
		}
	}
`